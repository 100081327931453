import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import { StaticQuery, graphql } from "gatsby";
import emailIcon from "../../images/icons/email.svg";
import pinterest from "../../images/pinterest.png";
import podcast from "../../images/podcast.png";
import youtube from "../../images/youtube.png";
import LazyIframe from "../../components/LazyIframe";
import MailChimpBanner from "../../components/Mailchimp-banner";
import PromoBanner from "../../components/PromoBanner";
import LHTBanner from "../../components/LHTBanner";

const Contact = (props) => {
  const contact = props.data.site.siteMetadata.contact;
  return (
    <Layout bodyclassName="page-contact">
      <SEO title="Contact Us | Learn Southern Vietnamese with HowToVietnamese" />
      <PromoBanner />
      <div className="contentContainer container margin-from-header mt-9">
        <div className="row pt-4 pt-md-8">
          <div className="col-12 col-md-8">
            <LazyIframe
              key="7b69a3d2-1665-4bef-841c-4a73b34af176"
              src="https://docs.google.com/forms/d/e/1FAIpQLSfjgCi3rHbPa9k85IrM3UA7JFVfcsZdWucpNzdfEun0AE3DQQ/viewform?embedded=true"
              width="100%"
              height="800"
              frameBorder="0"
            >
              Loading...
            </LazyIframe>
          </div>

          <div className="col-11 col-md-4">
            {/* social */}
            <div className="social-card">
              <img src={youtube} alt="youtube" />
              <p>
                <span>YouTube</span>
                <br />
                <a href={contact.youtube} target="_blank">
                  How To Vietnamese
                </a>
              </p>
            </div>

            <div className="social-card">
              <img src={podcast} alt="podcast" />
              <p>
                <span>Podcast</span>
                <br />
                <a href={contact.podcast} target="_blank">
                  Southern Vietnamese Lessons
                </a>
              </p>
            </div>

            <div className="social-card">
              <img src={pinterest} alt="pinterest" />
              <p>
                <span>Pinterest</span>
                <br />
                <a href={contact.pinterest} target="_blank">
                  /howtovietnamese
                </a>
              </p>
            </div>

            <div className="social-card">
              <img src={emailIcon} alt="email" />
              <p>
                <span>Email</span>
                <br />
                <a target="_blank" href={"mailto:" + contact.email}>
                  {contact.email}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 no-bg">
        <MailChimpBanner />
      </div>
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
              pinterest
              podcast
              youtube
            }
          }
        }
      }
    `}
    render={(data) => <Contact data={data} />}
  />
);
